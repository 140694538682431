import router from "@/router";

export default {
  state: {
    chat_id: '',
    //chat_num: 0,
    chats_list: [], //отображение в левой части чатов
    chats_pagination: null,
    chat: null, // текущий юзер с которым переписка
    chat_data: null, // текущий чат/инвойс

    open_mobile: false, // открытие chat_window на мобиле

    q: '',//поисковый запрос
    filter_id: '',//фильтр

    chat_timer_data: null,

    chat_scroll_perc: 0,
    chat_scroll_act: false,
    chat_price: 0,
    chat_page_size: 50, // even only!!
  },
  mutations: {
    SetChatData(state, data) {
      if (!data.suppress_log) {
        console.log('Chat: SetChatData', data.from, data);
      }

      if (data.chat_id) {
        state.chat_id = data.chat_id;
      }
      if (data.chats_list) {
        state.chats_list = Array.from(data.chats_list);
      }
      if (data.chats_list_update) {
        //state.chats_list = Array.from(data.chats_list);
        let cl = data.chats_list_update;
        if(state.chats_list) {
          const chat_index = state.chats_list.findIndex((e) => e.id === cl.data.id);
          cl.data = {...state.chats_list[chat_index], ...cl.data};
          if (chat_index >= 0) state.chats_list.splice(chat_index, 1);
          state.chats_list = [cl.data].concat(state.chats_list);
          // state.chat = {...cl.data}
        }
      }
      if (data.chats_list_delete) {
        const cl = data.chats_list_delete;
        if(state.chats_list) {
          const chat_index = state.chats_list.findIndex((e) => e.id === cl.data.id);
          if (chat_index >= 0) state.chats_list.splice(chat_index, 1);
        }
      }
      if (data.chat) {
        state.chat = {...data.chat};
      }
      if (data.chat_data) {
        state.chat_data = {...data.chat_data};
      }
      if (state.chat_data && state.chat_data.timer && data.set_timer_duration !== undefined) {
        state.chat_data.timer.duration = data.set_timer_duration;
      }
      if (data.chat_message_delete) {
        const cm = data.chat_message_delete;
        const message_index = state.chat_data.messages.findIndex((e) => e.id === cm);
        if (message_index >= 0) state.chat_data.messages.splice(message_index, 1);
      }

      if (data.user_unread_count) {
        if (state.chats_list) {
          let index = state.chats_list.findIndex((e) => e.id === data.user_unread_count.id);
          if (index >= 0) {
            state.chats_list[index].user_unread_count = data.user_unread_count.count_unread;
          }
        }
      }
      if (data.q || data.q === '') {
        console.log('Chat:', data.q);
        state.q = data.q;
      }
      if (data.filter_id || data.filter_id === '') {
        state.filter_id = data.filter_id;
      }
      if (state.chat_data && data.blocked !== undefined) {
        state.chat_data.blocked = data.blocked;
      }
      if (state.chat_data && data.favorites !== undefined) {
        state.chat_data.favorites = data.favorites;
      }
      if (state.chat_data && data.blocked !== undefined) {
        state.chat_data.blocked = data.blocked;
      }
      if (data.chat_timer_data !== undefined) {
        state.chat_timer_data = {...data.chat_timer_data};
        //chatting: Object { result: "chatting", time: 40 }
      }
      if (data.chat_scroll_perc !== undefined) {
        state.chat_scroll_perc = data.chat_scroll_perc;
        //chatting: Object { result: "chatting", time: 40 }
      }
      if (data.chat_scroll_act !== undefined) {
        state.chat_scroll_act = data.chat_scroll_act;
        //chatting: Object { result: "chatting", time: 40 }
      }
      if (data.open_mobile !== undefined) {
        state.open_mobile = data.open_mobile;
      }
      if (data.chat_price !== undefined) {
        state.chat_price = data.chat_price;
      }
    },
    ResetChatData(state) {
      console.log('Chats:ResetChatData:')
      state.chat_id = '';
      state.chats_list = null;
      state.chat = null;
      state.chat_data = null;
      state.open_mobile = false;
    },
    UpdateChatData(state, data) {
      console.log('Chats:UpdateChatData:', data)
      let unread = true;
      if (state.chat_scroll_perc >= 99) {
        state.chat_scroll_act = true
        // unread = false;
      }
      if (!state.chat_data) {
        state.chat_data = {};
      }
      if (!state.chat_data.messages) {
        state.chat_data.messages = [];
      }
      state.chat_data.messages.push({...data, ...{unread: unread}});
    },
  },
  actions: {
    ///chat/list?filterid=SelectedFilterID&q=SearchText
    GetChatsList({dispatch, state}, options) {
      let endpoint = '/chats';

      let call_options = options ? {...options} : {};
      if (call_options.new_list) {
        delete call_options.new_list;
      }
      if (state.q && state.q !== '') {
        call_options.q = state.q;
      }
      if (state.filter_id && state.filter_id !== '') {
        call_options.filter_id = state.filter_id;
      }

      dispatch('GetCall', {
        endpoint: endpoint,
        options: call_options,
        callback: (data) => {
          if(!options.new_list) {
            state.chats_list = state.chats_list.concat(data.rows);
          }else{
            state.chats_list = Array.from(data.rows);
          }
          state.chats_pagination = data.pagination;
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('Chat:', error_status);
        },
      })
    },
    UpdateChat({dispatch, commit, state}, options) {
      dispatch('GetCall', {
        endpoint: `/chats/${state.chat.id}/messages`,
        options: options.options,
        callback: (data) => {
          console.log('!!!!!!!!!!!!!!!!!!!!!!!!!!!!', data);
          if (data.timer && data.timer.duration !== undefined && data.timer.duration !== null) {
            commit('SetChatData', {chat_timer_data: {method: 'update', duration: data.timer.duration}, set_timer_duration: data.timer.duration});
          }

          if (options.callback) {
            switch (typeof options.callback) {
              case "string":
                dispatch(options.callback, data, {root: true});
                break;
              case "function":
                options.callback(data);
                break;
            }
          }

          let concatenated_data = {...data};

          if (state.chat_data && state.chat_data.messages) {

            if (options.options.size > 0) {
              for (let i = state.chat_data.messages.length - 1; i >= 0; i--) {
                let message = state.chat_data.messages[i];
                let found = false;

                for (let j = 0; j < data.messages.length; j++) {
                  if (message.id === data.messages[j].id) {
                    found = true;
                  }
                }
                if (!found) {
                  concatenated_data.messages.unshift(message);
                }
              }
            }
            if (options.options.size < 0) {
              for (let i = 0; i < state.chat_data.messages.length; i++) {
                let message = state.chat_data.messages[i];
                let found = false;

                for (let j = 0; j < data.messages.length; j++) {
                  if (message.id === data.messages[j].id) {
                    found = true;
                  }
                }
                if (!found) {
                  concatenated_data.messages.push(message);
                }
              }
            }

          }

          commit('SetChatData', {chat_data: concatenated_data, /*chat_timer_data: data.chatting*/});
          if (options.scroll_to_top) {
            state.chat_scroll_act = true;
          }
          let query = router.currentRoute._value.query.mid?{mid:router.currentRoute._value.query.mid}:{};
          router.push({path: `/chat/${options.user.user_id}`,query:query});

        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log(error_status);
        },
      })

    },
  },
  getters: {}
}
