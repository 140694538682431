<template>
  <main>
    <div class="container"
         v-if="user_info !== null && about_me_dd_data !== null && /*my_lifestyle_dd_data !== null &&*/ languages !== null && interests !== null">
      <section class="user">
        <div class="user-box">
          <picture class="user-box-bg">
            <!--              <source srcset="/pic/user-bg-female.webp 1x, /pic/user-bg-female@2x.webp 2x" type="image/webp">-->
            <!--              <source srcset="/pic/user-bg-female.jpg 1x, /pic/user-bg-female@2x.jpg 2x" type="image/jpeg">-->
            <img v-if="user_info.banner_photo !== null" :src="static_server + '/' + user_info.banner_photo" alt="">
          </picture>
          <div class="user-content">
            <div class="user-top">
              <div class="user-top-side">
                <a :href="void(0)" class="button button-black-transparent button-back" @click="$router.go(-1)">
                  <span>Back</span>
                </a>
              </div>
              <div class="user-top-side" v-if="my_user_id !== user_info.id">
                <button type="button" class="button button-black-transparent button-block-on" @click="DeleteFromBlock" v-if="blocked">
                  <span>Unblock user</span>
                </button>
                <button type="button" class="button button-black-transparent button-block-on" @click="BlockUser" v-else>
                  <span>Block user</span>
                </button>
                <button type="button" class="button button-fav popup-open" @click="DeleteFromFav" v-if="fav_id">
                  <span>Remove from favorites</span>
                </button>
                <button type="button" class="button button-fav popup-open" @click="AddToFav" v-else>
                  <span>To favorites</span>
                </button>
              </div>
            </div>
            <div class="user-main">
              <div class="user-avatar">
                <div class="user-avatar-image">
                  <picture v-if="user_info && user_info.main_photo">
                    <!--                      <source srcset="/pic/avatar-female-big.webp 1x, /pic/avatar-female-big@2x.webp 2x" type="image/webp">-->
                    <!--                      <source srcset="/pic/avatar-female-big.jpg 1x, /pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">-->
                    <img v-if="user_info.main_photo !== null" :src="static_server + '/' + user_info.main_photo" alt="">
                  </picture>
                  <template v-if="mood_img">
                    <img :src="mood_img" class="user-avatar-mood" :title="mood_description">
                  </template>
                </div>
                <ul class="user-avatar-buttons"
                    v-if="user_info && my_user_id !== user_info.id &&
                    ((user_info.is_seller && !my_user.is_seller) ||
                    (!user_info.is_seller && my_user.is_seller))
                ">
                  <li v-if="user_info.buttons.inviteBtn">
                    <button type="button" class="button button-chat" @click="SendInvite(user_info)">Send Invite</button>
                  </li>
                  <li v-if="user_info.buttons.chatNowBtn">
                    <button type="button" class="button button-chat" @click="StartChat(user_info)">Chat</button>
                  </li>
                  <li v-if="user_info.buttons.letterBtn">
                    <button type="button" class="button button-mail" @click="SendLetter(user_info)">Send letter</button>
                  </li>
                </ul>
              </div>
              <div class="user-name">
                <div class="user-name-box">
                  <h1>{{ user_info.user_name }}<template v-if="user_info.user_name && age">,</template> {{ age }}</h1>
                  <div class="user-sex user-sex-female" v-if="!user_info.male"></div>
                  <div class="user-sex user-sex-male" v-else></div>
                </div>
                <!--                <div class="user-status">Online via Mobile</div>-->
              </div>
            </div>
          </div>
        </div>
      </section>
      <div class="block">
        <section class="box" v-if="user_info.about_text && user_info.about_text!==''">
          <div class="box-content">
            <h3>A few words about myself</h3>
            <div class="line-clamp">
              <p>{{ user_info.about_text }}</p>
            </div>

            <!--            <a href="javascript:void(0);" class="more-link">
                          <span>Read more</span>
                          <span>Close</span>
                        </a>
                        -->
          </div>
        </section>
        <div class="grid">
          <div class="grid-box">
            <div @click="OpenViewPhotos(0)" class="media-gallery-link popup-open">
              <picture>
                <!--                <source srcset="/pic/media-gallery-photos.webp 1x, /pic/media-gallery-photos@2x.webp 2x" type="image/webp">-->
                <!--                <source srcset="/pic/media-gallery-photos.jpg 1x, /pic/media-gallery-photos@2x.jpg 2x" type="image/jpeg">-->
                <img src="/pic/media-gallery-photos.jpg" alt="">
              </picture>
              <span>My photos</span>
              <i v-if="user_info.photos">{{ user_info.photos.length }}</i>
              <i v-else>0</i>
            </div>
          </div>
          <div class="grid-box">
            <div @click="OpenViewVideos(0)" class="media-gallery-link popup-open">
              <picture>
                <!--                <source srcset="/pic/media-gallery-videos.webp 1x, /pic/media-gallery-videos@2x.webp 2x" type="image/webp">-->
                <!--                <source srcset="/pic/media-gallery-videos.jpg 1x, /pic/media-gallery-videos@2x.jpg 2x" type="image/jpeg">-->
                <img src="/pic/media-gallery-videos.jpg" alt="">
              </picture>
              <span>My videos</span>
              <i v-if="user_info.videos">{{ user_info.videos.length }}</i>
              <i v-else>0</i>
            </div>
          </div>
        </div>
        <div class="grid">
          <div class="grid-box">
            <section class="box">
              <div class="box-content">
                <h3>My interests</h3>
                <ul v-if="user_info.interests && user_info.interests.length>0" class="interests">
                  <template v-for="(value, index) in user_info.interests" v-bind:key="index">
                    <template v-for="(value2, index2) in interests" v-bind:key="index2">
                      <li v-if="value2.id === value.id">
                      <span class="interest-content no-checkbox">
                        <i>
<!--                          <inline-svg :src="static_server + value.icon"></inline-svg>-->
                          <img :src="static_server + '/' + value2.icon1">

<!--                          <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">-->
<!--                            <path d="M6.92038 18.4011C6.92038 18.4011 7.37839 16.5865 6.32582 15.3305L7.58295 12.3032L14.2957 16.5938L15.3707 14.9091L7.68522 9.99913L7.68579 9.99688L7.67567 9.99238L7.52338 9.89572L7.50596 9.92326L4.90572 8.84428L2.68706 0.558594H0.532471L3.21644 10.583L2.2594 12.8854C2.25828 12.8899 2.1088 13.3041 2.06384 14.1656C2.06384 14.1656 1.61258 17.6807 4.05546 19.6122L1.89019 27.5281H4.09592L5.81499 21.2396L11.8146 27.5281H14.8891L14.8931 27.5258L8.63945 20.9682L6.92038 18.4011Z"/>-->
<!--                            <path d="M7.78127 9.21147C9.0184 9.21147 10.0213 8.20858 10.0213 6.97146C10.0213 5.73433 9.0184 4.73145 7.78127 4.73145C6.54415 4.73145 5.54126 5.73433 5.54126 6.97146C5.54126 8.20858 6.54415 9.21147 7.78127 9.21147Z"/>-->
<!--                          </svg>-->
                        </i>
                        <span>{{ value2.title }}</span>
                      </span>
                      </li>
                    </template>
                  </template>
                </ul>
                <div v-else class="empty">Empty</div>
              </div>
            </section>
          </div>
          <div class="grid-box">
            <section class="box">
              <div class="box-content">
                <h3>About me</h3>
                <table v-if="user_info.about && user_info.about.length>0" class="i-table">
                  <template v-for="(value, index) in about_me_dd_data" v-bind:key="index">
                    <template v-if="user_info.about.map(e => { return e.item_id; }).indexOf(value.id) > -1">
                      <template v-for="(value2, index2) in user_info.about" v-bind:key="index2">
                        <template v-if="value.id === value2.item_id">
                          <tr>
                            <td>
                              <div class="i-table-title">
                                <span>{{ value.title }}</span>
                              </div>
                            </td>
                            <template v-for="(value3, index3) in value.options" v-bind:key="index3">
                              <template v-if="value3.id === value2.item_options_id">
                                <td>{{ value3.title }}</td>
                              </template>
                            </template>
                          </tr>
                        </template>
                      </template>
                    </template>
                  </template>
                </table>
                <div v-else class="empty">Empty</div>
              </div>
            </section>
          </div>
          <!--
                    <div class="grid-box">
                      <section class="box">
                        <div class="box-content">
                          <h3>My lifestyle</h3>
                          <table class="i-table">
                            <template v-for="(value, index) in my_lifestyle_dd_data" v-bind:key="index">
                              <template v-if="user_info.my_lifestyle.map(e => { return e.item_id; }).indexOf(value.id) > -1">
                                <template v-for="(value2, index2) in user_info.my_lifestyle" v-bind:key="index2">
                                  <template v-if="value.id === value2.item_id">
                                    <tr>
                                      <td>
                                        <div class="i-table-title">
                                          <span>{{ value.title }}</span>
                                        </div>
                                      </td>
                                      <template v-for="(value3, index3) in value.options" v-bind:key="index3">
                                        <template v-if="value3.id === value2.item_options_id">
                                          <td>{{ value3.title }}</td>
                                        </template>
                                      </template>
                                    </tr>
                                  </template>
                                </template>
                              </template>
                            </template>
                          </table>
                        </div>
                      </section>
                    </div>
          -->
        </div>
        <section class="box" v-if="user_info.looking_for && my_lifestyle_filled > 0">
          <div class="box-content">
            <h3>I'm looking for</h3>
            <div :class="read_more_looking_for?'':'line-clamp'">
              <b>
                <template v-if="user_info.looking_for.male">Male,</template>
                <template v-if="user_info.looking_for.female">
                  <template v-if="user_info.looking_for.male">female,</template>
                  <template v-else>Female,</template>
                </template>
                <template v-if="user_info.looking_for.age_min !== ''">{{ ' ' + user_info.looking_for.age_min }}</template>
                <template v-if="user_info.looking_for.age_max !== ''">{{ ' - ' + user_info.looking_for.age_max + ' ' }}</template>
                years old.
              </b><br>
              <span>Few words:</span>&nbsp;<b>{{ user_info.looking_for.text }}</b><br>

              <template v-for="(value, index) in about_me_dd_data" v-bind:key="index">
                <template v-if="user_info.looking_for.drops.map(e => { return e.item_id; }).indexOf(value.id) > -1">
                  <template v-for="(value2, index2) in user_info.looking_for.drops" v-bind:key="index2">
                    <template v-if="value.id === value2.item_id">
                      <span>{{ value.title }}</span>&nbsp;
                      <template v-for="(value3, index3) in value.options" v-bind:key="index3">
                        <template v-if="value3.id === value2.item_options_id">
                          <b>{{ value3.title }}</b><br>
                        </template>
                      </template>
                    </template>
                  </template>
                </template>
              </template>

            </div>
            <a v-if="my_lifestyle_filled > 4" class="more-link" @click="ShowMoreInfo">
              <span v-if="!read_more_looking_for">Read more</span>
              <span v-else>Close</span>
            </a>
          </div>
        </section>
      </div>
      <!--
            <section class="block">
              <h2>See more like Angie</h2>
              <div class="persons">
                <a href="#" class="person">
                  <div class="person-image">
                    <picture>
                      <source srcset="/pic/avatar-female-big.webp 1x, /pic/avatar-female-big@2x.webp 2x" type="image/webp">
                      <source srcset="/pic/avatar-female-big.jpg 1x, /pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">
                      <img src="/pic/avatar-female-big.jpg" alt="">
                    </picture>
                    <img src="/img/mood-1.svg" class="person-mood" alt="">
                    <ul class="person-media">
                      <li class="person-photo">22</li>
                      <li class="person-video">4</li>
                    </ul>
                  </div>
                  <div class="person-main">
                    <div class="person-name is-online">Yana, 25, UA</div>
                    <div class="person-text">It is a long established fact that a reader will be distracted by the readable content of a page
                    </div>
                  </div>
                </a>
                <a href="#" class="person">
                  <div class="person-image">
                    <picture>
                      <source srcset="/pic/avatar-female-big.webp 1x, /pic/avatar-female-big@2x.webp 2x" type="image/webp">
                      <source srcset="/pic/avatar-female-big.jpg 1x, /pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">
                      <img src="/pic/avatar-female-big.jpg" alt="">
                    </picture>
                    <img src="/img/mood-1.svg" class="person-mood" alt="">
                    <ul class="person-media">
                      <li class="person-photo">22</li>
                      <li class="person-video">4</li>
                    </ul>
                  </div>
                  <div class="person-main">
                    <div class="person-name is-online">Yana, 25, UA</div>
                    <div class="person-text">It is a long established fact that a reader will be distracted by the readable content of a page
                    </div>
                  </div>
                </a>
                <a href="#" class="person">
                  <div class="person-image">
                    <picture>
                      <source srcset="/pic/avatar-female-big.webp 1x, /pic/avatar-female-big@2x.webp 2x" type="image/webp">
                      <source srcset="/pic/avatar-female-big.jpg 1x, /pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">
                      <img src="/pic/avatar-female-big.jpg" alt="">
                    </picture>
                    <img src="/img/mood-1.svg" class="person-mood" alt="">
                    <ul class="person-media">
                      <li class="person-photo">22</li>
                      <li class="person-video">4</li>
                    </ul>
                  </div>
                  <div class="person-main">
                    <div class="person-name is-online">Yana, 25, UA</div>
                    <div class="person-text">It is a long established fact that a reader will be distracted by the readable content of a page
                    </div>
                  </div>
                </a>
                <a href="#" class="person">
                  <div class="person-image">
                    <picture>
                      <source srcset="/pic/avatar-female-big.webp 1x, /pic/avatar-female-big@2x.webp 2x" type="image/webp">
                      <source srcset="/pic/avatar-female-big.jpg 1x, /pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">
                      <img src="/pic/avatar-female-big.jpg" alt="">
                    </picture>
                    <img src="/img/mood-1.svg" class="person-mood" alt="">
                    <ul class="person-media">
                      <li class="person-photo">22</li>
                      <li class="person-video">4</li>
                    </ul>
                  </div>
                  <div class="person-main">
                    <div class="person-name is-online">Yana, 25, UA</div>
                    <div class="person-text">It is a long established fact that a reader will be distracted by the readable content of a page
                    </div>
                  </div>
                </a>
                <a href="#" class="person">
                  <div class="person-image">
                    <picture>
                      <source srcset="/pic/avatar-female-big.webp 1x, /pic/avatar-female-big@2x.webp 2x" type="image/webp">
                      <source srcset="/pic/avatar-female-big.jpg 1x, /pic/avatar-female-big@2x.jpg 2x" type="image/jpeg">
                      <img src="/pic/avatar-female-big.jpg" alt="">
                    </picture>
                    <img src="/img/mood-1.svg" class="person-mood" alt="">
                    <ul class="person-media">
                      <li class="person-photo">22</li>
                      <li class="person-video">4</li>
                    </ul>
                  </div>
                  <div class="person-main">
                    <div class="person-name is-online">Yana, 25, UA</div>
                    <div class="person-text">It is a long established fact that a reader will be distracted by the readable content of a page
                    </div>
                  </div>
                </a>
              </div>
            </section>
      -->
    </div>
  </main>
</template>

<script>
// import InlineSvg from 'vue-inline-svg';
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "UserView",
  components: {
    // InlineSvg
  },
  data: () => {
    return {
      user_info: null,
      read_more_looking_for: false,
      mood_img: null,
      mood_description: '',
      // about_me_dd_data: null,
      // my_lifestyle_dd_data: null,
      // languages: null,
      // interests: null,
      fav_id: null,
      blocked: null,
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      languages: state => state.User.languages,
      interests: state => state.User.interests,
      my_user_id: state => state.User.user_data.id,
      my_user: state => state.User.user_data,
    }),
    my_lifestyle_filled() {
      let line_num = 0;

      if (
          this.user_info.looking_for.male || this.user_info.looking_for.female ||
          this.user_info.looking_for.age_min || this.user_info.looking_for.age_max
      ) {
        line_num++;
      }
      if (this.user_info.looking_for.text) {
        line_num++;
      }
      if (this.user_info.looking_for.drops) {
        line_num += this.user_info.looking_for.drops.length;
      }

      return line_num;
    },
    age: function () {
      let day = 1;
      let month = 0;
      let year = 1970;

      if (this.user_info !== undefined) {
        if (this.user_info.birthday_day !== '') {
          day = this.user_info.birthday_day;
        }
        if (this.user_info.birthday_month !== '') {
          month = this.user_info.birthday_month;
        }
        if (this.user_info.birthday_year !== '') {
          year = this.user_info.birthday_year;
        }
      }
      let user_date = new Date(year, month, day);
      let ageDifMs = Date.now() - user_date.getTime();
      let ageDate = new Date(ageDifMs);

      let ret = '';

      if (this.user_info !== undefined) {
        if (this.user_info.birthday_year && this.user_info.birthday_year !== '') {
          ret = Math.abs(ageDate.getUTCFullYear() - 1970);
        }
      }

      return ret;
    },
  },
  mounted() {
    this.GetUserData();
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetLetterData', 'SetInviteData']),
    ...mapActions(['GetCall', 'PostCall']),
    ShowMoreInfo() {
      this.read_more_looking_for = !this.read_more_looking_for;
    },
    AddToFav() {
      this.SetPopupData({
        popup_additional_data: {
          user_id: this.user_info.id,
          callback: () => {
            this.GetUserData();
          }
        },
        private_flag: false,
        popup_name: 'add_fav',
        popup_opened: true,
      })
    },
    DeleteFromFav() {
      this.SetPopupData({
        popup_additional_data: {
          fav_id: this.fav_id,
          callback: () => {
            this.GetUserData();
          }
        },
        private_flag: false,
        popup_name: 'delete_fav',
        popup_opened: true,
      })
    },

    BlockUser() {
      this.SetPopupData({
        popup_additional_data: {
          user_name: this.user_info.user_name,
          user_id: this.user_info.id,
          callback: () => {
            this.GetUserData();
          }
        },
        private_flag: false,
        popup_name: 'add_block',
        popup_opened: true,
      })
    },
    DeleteFromBlock() {
      this.SetPopupData({
        popup_additional_data: {
          blocked: this.blocked,
          callback: () => {
            this.GetUserData();
          }
        },
        private_flag: false,
        popup_name: 'delete_block',
        popup_opened: true,
      })
    },

    GetUserData() {
      this.GetCall({
        endpoint: '/users/view/' + this.$route.query.id,
        options: '',
        callback: (data) => {

          if (data.fav_id) {
            this.fav_id = data.fav_id;
          } else {
            this.fav_id = null;
          }
          if (data.blocked) {
            this.blocked = data.blocked;
          } else {
            this.blocked = null;
          }

          this.user_info = data.user;

          if (this.user_info.mood_id !== null && this.user_info.mood_id !== undefined) {
            this.mood_img = `/img/mood-${parseInt(this.user_info.mood_id) + 1}.svg`;
          }
          if (this.user_info.mood_description) {
            this.mood_description = this.user_info.mood_description;
          }
          // this.about_me_dd_data = data.user.about;
          // this.my_lifestyle_dd_data = data.my_lifestyle;
          // this.languages = data.languages;
          // this.interests = data.interests;
        },
      })
    },
    OpenViewPhotos(index) {
      if (this.user_info.photos.length > 0) {
        this.SetPopupData({
          photo_view_data: this.user_info.photos,
          photo_view_index: index,
          private_flag: false,
          popup_name: 'view_photo',
          popup_opened: true,
        })
      }
    },
    OpenViewVideos(index) {
      if (this.user_info.videos.length > 0) {
        this.SetPopupData({
          video_view_data: this.user_info.videos,
          video_view_index: index,
          private_flag: false,
          popup_name: 'view_video',
          popup_opened: true,
        })
      }
    },

    SendLetter(value) {
      // new_letter_data
      this.SetLetterData({
        write_letter: true,
        write_letter_user: value,
      });
      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/letters`,query:query});
    },
    SendInvite(value) {
      this.SetInviteData({
        invite_send_obj: {
          user_id: value.id,
        }
      });
      this.SetPopupData({
        popup_name: 'send_invite',
        popup_opened: true
      })
    },
    StartChat(value) {
      this.PostCall({
        endpoint: '/chats',
        options: {
          user_id: value.id,
        },
        callback: (response) => {
          console.log('SearchResult:StartChat:', response);
          if (response && response.user_id) {
            let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
            this.$router.push({path: `/chat/${response.user_id}`, query:query});
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('SearchResult:StartChat:Error:', error_status);
        },
      })

    },

  },
}
</script>

<style scoped>

</style>
