<template>
  <form class="chat-form" v-if="invite">
    <textarea class="textfield" placeholder="My message..." v-model="chat_message" @keydown="myEventHandler"></textarea>
    <ul class="chat-form-buttons" v-if="user_data">
<!--
      <li>
        <button type="button" class="chat-form-translate"></button>
      </li>
-->
      <li>
        <button type="button" class="chat-form-smile"></button>
      </li>
      <li>
        <div class="chat-form-send-message" @click="ChatSend"></div>
      </li>
    </ul>
  </form>
  <form class="chat-form" v-else>
    <textarea class="textfield" placeholder="My message..." disabled></textarea>
    <ul class="chat-form-buttons">
<!--
      <li>
        <button type="button" class="chat-form-translate" style="cursor: default; opacity: .5"></button>
      </li>
-->
      <li>
        <button type="button" class="chat-form-smile" style="cursor: default; opacity: .5"></button>
      </li>
      <li>
        <div class="chat-form-send-message" style="cursor: default; opacity: .5"></div>
      </li>
    </ul>
  </form>


</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "I_SendField",
  props: {},
  computed: {
    ...mapState({
      invite: state => state.Invites.invite,
      user_data: state => state.User.user_data,
      chat: state => state.Chat.chat,
    }),
  },
  data: () => {
    return {
      chat_message: '',
      chat_message_cache: '',
      chat_open_user_id: null,
      presents_opened: false,
      gifts_list: [],
    }
  },
  emits: [
    'onWrite'
  ],
  methods: {
    ...mapMutations(['SetPopupData', 'SetLetterData', 'SetChatData']),
    ...mapActions(['SocketEmit', 'PostCall', 'SendInvite']),

    StartChat() {
      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/chat/${this.invite.user_id}`, query:query});
    },

    WriteReply() {
      this.SetLetterData({write_letter: true});
      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/letters/${this.invite.user_id}`, query:query});
    },

    myEventHandler(event) {
      if ((event.ctrlKey || event.metaKey) && (event.keyCode === 13 || event.keyCode === 10)) {
        console.log(event);
        this.ChatSend();
      }
    },
    ChatSend() {
      let trimmed='';
      if(this.chat_message){
        trimmed = this.chat_message.trim();
      }


      if (trimmed.length !== 0 && trimmed !== ' ') {
        if(this.user_data.is_seller){
/*
          let send = {
            type: 'chat_messages',
            data: {
              type: 1, //1-text, 2-photos, 3-gifts, 4-videos
              is_invite: true,
              chat_id: this.invite.id,
              message: trimmed,
            },
            callback: (response) => {
              console.log('I_SendField:InviteSend:', response);
              if (response.result) {
                this.chat_message_cache = '';
              } else {
                this.chat_message = this.chat_message_cache;

                if (response && response.error === 'blacklist') {
                  this.SetPopupData({
                    private_flag: false,
                    popup_name: 'blocked',
                    popup_opened: true,
                  })
                }
              }
            }
          }
          this.SocketEmit(send);
*/

          let sendObj = {
            is_invite: true,
            user_id: this.invite.user_id,
            message: trimmed,
            callback: (response) => {
              console.log('I_SendField:InviteSend:', response);
              if (response.result==='ok') {
                this.chat_message_cache = '';
              } else {
                this.chat_message = this.chat_message_cache;

                if (response && response.error === 'blacklist') {
                  this.SetPopupData({
                    private_flag: false,
                    popup_name: 'blocked',
                    popup_opened: true,
                  })
                }
              }
            }
          }
          this.SendInvite(sendObj);


          this.chat_message_cache = trimmed;
          this.chat_message = '';

        }else{
          let send = {
            type: 'chat_messages',
            data: {
              type: 1, //1-text, 2-photos, 3-gifts, 4-videos
              chat_id: this.invite.id,
              message: trimmed,
            },
            callback: (response) => {
              console.log('I_SendField:InviteSend:', response);
              if (response.result) {
                let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
                this.$router.push({path: `/chat/${this.invite.user_id}`, query:query});
                this.chat_message_cache = '';
              } else {
                this.chat_message = this.chat_message_cache;

                if (response && response.error === 'blacklist') {
                  this.SetPopupData({
                    private_flag: false,
                    popup_name: 'blocked',
                    popup_opened: true,
                  })
                }
              }
            }
          }
          this.SocketEmit(send);
          this.chat_message_cache = trimmed;
          this.chat_message = '';
        }

      }
    },
  },
  watch: {
    photo_selected: {
      handler(newVal) {
        if (newVal) {
          if (newVal.length !== 0) {
            if (this.photo_selected_target === 'chat') {
              this.PhotoSend();
            }
          }
        }
      },
      deep: true
    },
  }

}
</script>

<style scoped>

</style>
