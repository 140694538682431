<template>
  <main>
    <div class="container">
      <div class="panel-top">
        <div class="panel-top-side">
          <div class="panel-top-box">
            <h1>Search results</h1>
          </div>
        </div>
        <div class="panel-top-side">
          <div class="panel-top-box">
            <button type="button" class="button button-transparent" @click="GetResult()">Refresh</button>
          </div>
        </div>
      </div>
      <template v-if="search_result">
        <section class="search-result" v-if="search_result.rows && search_result.rows.length > 0">
          <template v-for="(value,index) in search_result.rows" v-bind:key="index">
            <article>
              <div class="person tip">

                <router-link :to="{path: `/user-view`, query:{...{id:value.id},...mid}}" class="person-image">
<!--
                <div
                    class="person-image"
                    @mouseover="value.hover = true"
                    @mouseleave="value.hover = false"
                >
-->
                  <picture v-if="value.main_photo">
                    <img :src="static_server + '/' + value.main_photo" alt="">
                  </picture>
                  <img v-if="value.mood_id" class="person-mood"
                       :src="'img/mood-'+(value.mood_id+1)+'.svg'"
                       :title="value.mood_description?value.mood_description:''">
                  <ul class="person-media">
                    <li class="person-photo" v-if="value.photosCount">{{ value.photosCount }}</li>
                    <li class="person-video" v-if="value.videos_count">{{ value.videos_count }}</li>
                  </ul>
                </router-link>
                <div class="person-main">
                  <div class="person-main-row">
                    <h2 class="person-name" :class="value.online ? 'is-online':''" v-if="value.country">
                      {{ value.user_name }}, {{ value.age }}
                    </h2>
                    <h2 class="person-name" :class="value.online ? 'is-online':''" v-else-if="value.birthday_day">
                      {{ value.user_name }}, {{ value.age }}
                    </h2>
<!--                    <router-link :to="'/user-view?id='+value.id" class="person-profile">Profile</router-link>-->
                    <div class="person-profile" @click="value.hover = !value.hover">Details</div>
                  </div>
                </div>
              </div>
              <div
                  class="tip-content"
                  :class="value.hover ? 'show':''"
                  :id="'person-'+index"
                  @mouseover="value.hover = true"
                  @mouseleave="value.hover = false"
              >
                <div class="person-info">
                  <div class="person-info-text" v-html="value.about_text"></div>
                  <div class="person-info-interests">
                    <strong>My interests:</strong>
                    <ul class="interests">
                      <template v-for="(value2,index2) in value.interests" v-bind:key="index2">
                        <template v-for="(value3,index3) in interests" v-bind:key="index3">
                          <template v-if="value2.id === value3.id">
                            <li v-if="index2<5">
                              <label class="interest">
                                <input type="checkbox" checked disabled>
                                <span class="interest-content">
                                <i>
                                  <img :src="static_server + '/' + value3.icon1">
                                  </i>
                                <span>{{ value3.title }}</span>
                              </span>
                              </label>
                            </li>
                          </template>
                        </template>
                      </template>
                      <li v-if="value.interests.length > 5">
                        <router-link :to="{path:`/user-view`,query:{...{id:value.id},...mid}}" class="interest-more">
                          <i></i>
                          <span>{{ value.interests.length - 5 }} more </span>
                        </router-link>
                      </li>

                    </ul>
                  </div>
                  <div class="person-info-buttons">
                    <template v-if="value.buttons">
                      <a @click="SendLetter(value)" class="button" v-if="value.buttons.letterBtn">Send letter</a>
                      <a @click="StartChat(value)" class="button" v-if="value.buttons.chatNowBtn">Chat now</a>
                      <a @click="SendInvite(value)" class="button" v-if="value.buttons.inviteBtn">Invite</a>
                    </template>
                  </div>
                </div>
              </div>
            </article>
          </template>
        </section>
        <template v-else>
          <div class="search-result-empty">Nothing found</div>
        </template>
      </template>

      <div class="panel-bottom" v-if="search_result.pagination && search_result.pagination.totalPage > 1">
        <div class="panel-bottom-box">
          <ul class="pager">
            <li>
              <div class="pager-next" :class="page>1?'':'disabled'" @click="PrevPage()"/>
            </li>
            <template v-if="page === 4">
              <li @click="SetPage(1)">
                <span>{{ 1 }}</span>
              </li>
            </template>
            <template v-if="page === 5">
              <li @click="SetPage(1)">
                <div>{{ 1 }}</div>
              </li>
              <li @click="SetPage(2)">
                <div>{{ 2 }}</div>
              </li>
            </template>
            <template v-if="page > 5">
              <li @click="SetPage(1)">
                <div>{{ 1 }}</div>
              </li>
              <li>...</li>
            </template>
            <template v-for="i in search_result.pagination.totalPage" :key="i">
              <template v-if="i >= page - 2 && i <= page + 2">
                <li v-if="i === page" @click="SetPage(i)">
                  <span>{{ i }}</span>
                </li>
                <li v-else @click="SetPage(i)">
                  <div>{{ i }}</div>
                </li>
              </template>
            </template>
            <template v-if="page === search_result.pagination.totalPage - 3">
              <li @click="SetPage(search_result.pagination.totalPage)">
                <div>{{ search_result.pagination.totalPage }}</div>
              </li>
            </template>
            <template v-if="page === search_result.pagination.totalPage - 4">
              <li @click="SetPage(search_result.pagination.totalPage - 1)">
                <div>{{ search_result.pagination.totalPage - 1 }}</div>
              </li>
              <li @click="SetPage(search_result.pagination.totalPage)">
                <div>{{ search_result.pagination.totalPage }}</div>
              </li>
            </template>
            <template v-if="page < search_result.pagination.totalPage - 4">
              <li>...</li>
              <li @click="SetPage(search_result.pagination.totalPage)">
                <div>{{ search_result.pagination.totalPage }}</div>
              </li>
            </template>
            <li>
              <div class="pager-prev" :class="page<search_result.pagination.totalPage?'':'disabled'" @click="NextPage()"/>
            </li>
          </ul>
        </div>
        <!--        <div class="panel-bottom-box">-->
        <!--          <a href="#" class="button">Load more</a>-->
        <!--        </div>-->
      </div>
    </div>
  </main>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";
import router from "@/router";

export default {
  name: "SearchResult",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      about_me_dd_data: state => state.User.about_me_dd_data,
      user_data: state => state.User.user_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      interests: state => state.User.interests,
      search_result: state => state.Search.search_result,
    }),
    mid() {
      return this.$route.query.mid?{mid:this.$route.query.mid}:{};
    },
  },
  data: function () {
    return {
      filterPattern: {
        mood: [],
        interests: [],
        categories: [],
      },
      page: 1,
      page_size: 20,
      last_showed: 0,
    }
  },
  mounted() {
    //console.log('router query', router.currentRoute._value.query);
    this.filterPattern = {...router.currentRoute._value.query};
    this.GetResult();
    // if (this.search_result.length === 0) {
    //   this.$router.push({path: '/user-profile', query: {}});
    // }
  },
  methods: {
    ...mapActions(['PostCall', 'GetCall','GetSearchResult']),
    ...mapMutations(['SetLetterData', 'SetInviteData', 'SetPopupData', 'SetSearchResult']),
    scrollToTop() {
      window.scrollTo({top: 0, behavior: 'smooth'});
    },
    NextPage() {
      if (this.page < this.search_result.pagination.totalPage) {
        this.page++;
        this.$nextTick(() => {
          this.scrollToTop();
        })
        this.GetResult();
      }
    },
    PrevPage() {
      if (this.page > 1) {
        this.page--;
        this.$nextTick(() => {
          this.scrollToTop();
        })
        this.GetResult();
      }
    },
    SetPage(n) {
      this.page = n;
      this.$nextTick(() => {
        this.scrollToTop();
      })
      this.GetResult();
    },


    GetResult() {
      const sendOptions = {...this.filterPattern, ...{size: this.page_size, page: this.page}}
      this.GetSearchResult(sendOptions);
    },

    SendLetter(value) {
      // new_letter_data
      this.SetLetterData({
        write_letter: true,
        write_letter_user: value,
      });
      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/letters`, query:query});
    },
    SendInvite(value) {
      this.SetInviteData({
        invite_send_obj: {
          user_id: value.id,
        }
      });
      this.SetPopupData({
        popup_name: 'send_invite',
        popup_opened: true
      })

      // let send = {
      //   type: 'invite',
      //   data: {
      //     user_id: this.search_result[index].id,
      //     text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
      //   },
      // }
      // this.SocketEmit(send);
      // this.$router.push({path: '/invites'});
    },
    StartChat(value) {
      this.PostCall({
        endpoint: '/chats',
        options: {
          user_id: value.id,
        },
        callback: (response) => {
          console.log('SearchResult:StartChat:', response);
          if (response && response.user_id) {
            let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
            this.$router.push({path: `/chat/${response.user_id}`, query:query});
          }
        },
        on_error: (error) => {
          let error_status = error.response.status;
          console.log('SearchResult:StartChat:Error:', error_status);
        },
      })

    },

    age(birthday_day, birthday_month, birthday_year) {
      let age = '';
      if (birthday_day !== null && birthday_month !== null && birthday_year !== null) {

        let day = 1;
        let month = 0;
        let year = 1970;

        day = birthday_day;
        month = birthday_month;
        year = birthday_year;

        let user_date = new Date(year, month, day);
        let ageDifMs = Date.now() - user_date.getTime();
        let ageDate = new Date(ageDifMs);
        age = Math.abs(ageDate.getUTCFullYear() - 1970);
      }
      return age
    },

  },
/*
  watch: {
    '$route.query': {
      handler: function () {
        this.filterPattern = {...router.currentRoute._value.query};
        this.GetResult();
      },
      deep: true,
      immediate: true
    }
  }
*/
}
</script>

<style scoped>

</style>
