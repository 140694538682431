<template>
  <div class="chat-user"
       :class="chat.user_id === communication.user_id ? 'is-active':''"
       @click="UserClicked">
    <div class="chat-user-avatar" :class="communication.online ? 'is-online':''" @click="GoToUserView">
      <picture v-if="communication && communication.user_photo_url">
        <!--              <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
        <!--              <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
        <img :src="static_server+'/'+communication.user_photo_url" alt="">
      </picture>
    </div>
    <div class="chat-user-info">
      <div class="chat-user-name-date">
        <div class="chat-user-name">{{ communication.user_name }}</div>
        <time class="chat-user-date" v-if="communication && communication.updated_at">
          {{ new Date(communication.updated_at).getHours() }}:{{ new Date(communication.updated_at).getMinutes() }}
        </time>
      </div>
      <div class="chat-user-links-about">
        <ul class="chat-user-links">
          <li class="chat-user-links-photo"></li>
          <li class="chat-user-links-video"></li>
        </ul>
        <div class="chat-user-about">{{ communication.message }}</div>
        <div class="chat-user-messages-dummy" v-if="communication.count_unread > 0">{{ communication.count_unread }}</div>
        <div title="number of unread messages"
             class="chat-user-unread-messages" v-if="communication.count_unread > 0">{{ communication.count_unread }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "CHT_ChatElement",
  props: {
    communication: Object,
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      chat: state => state.Chat.chat,
      chat_page_size: state => state.Chat.chat_page_size,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
    }
  },
  mounted() {
    if (this.communication.user_id === this.chat.user_id) {
      setTimeout(() => {
        // this.UpdateChat({user: this.communication, options: {size: this.chat_page_size, page: 0}});
        this.UpdateChat({
          user: this.communication,
          options: {size: this.chat_page_size},
          callback: (data) => {
            console.log('CHT_ChatElement: mounted: callback: ', data);
          }
        });
      }, 150);
    }
  },
  methods: {
    ...mapActions(['GetCall', 'UpdateChat']),
    ...mapMutations(['SetChatData']),
    data_ms(date, iso) {
      // this.test_count++;
      // console.log('CHT_Window date:', Date.UTC(date.year, date.month, date.day), this.test_count);
      if (!iso) {
        return Date.UTC(date.year, date.month, date.day);
      } else {
        return Date(date);
      }
    },
    GoToUserView(e) {
      e.stopPropagation();
      let query = this.$route.query.mid?{mid:this.$route.query.mid,id: this.communication.user_id}:{id: this.communication.user_id};
      this.$router.push({path: `/user-view`, query: query});
    },
    UserClicked() {
      this.SetChatData({
        chat_id: this.communication.user_id,
        chat: this.communication,
        open_mobile: true,
      });
      this.UpdateChat({user: this.communication, options: {page: 0}});
    },
  },
}
</script>

<style scoped>

</style>
