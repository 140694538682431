<template>
  <perfect-scrollbar
      :watchOptions="true"
      :options="scroll_options"
      class="tip-content" id="notifications">
    <div class="notifications">
      <template v-for="(value,index) in dd_messages" v-bind:key="index">

        <div class="notification">
          <div class="notification-image" :class="value.online?'is-online':''">
            <picture v-if="value.user_photo_url">
              <img :src="static_server + '/' + value.user_photo_url" alt="">
            </picture>
          </div>
          <div class="notification-content">
            <div class="notification-header">{{ value.user_name }}, {{ value.age }}</div>
            <div class="notification-text notification-overflow" v-if="value.type==='chat'">{{ value.message }}</div>
            <div class="notification-text notification-overflow" v-if="value.type==='letter'">{{ value.subject }}</div>
            <div class="button button-block button-reply" @click="MessageClicked(value)" v-if="value.type==='chat'">To Chat</div>
            <div class="button button-block button-reply" @click="LetterClicked(value)" v-if="value.type==='letter'">Reply</div>
          </div>
        </div>

      </template>

    </div>
  </perfect-scrollbar>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "DDMessages",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      dd_messages: state => state.Dropdowns.dd_messages,
      chats_list: state => state.Chat.chats_list,
      communication_type: state => state.Communications.communication_type,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
        // scrollYMarginOffset: -15
      },
      obj: {
        "chats": [
          {
            "id": 1,
            "uuid": "1488624a-ee81-4c76-8d84-e03c013166f7",
            "created_at": "2022-09-18T20:59:11.423Z",
            "content": {
              "id": "b622e058-c3ba-4600-9cb1-c81e4da3c932",
              "type": "chat",
              "content": {
                "text": "xcrdxrctfv"
              },
              "self": false,
              "chat_id": "9ec67623-e03e-4b60-88b3-3d6d3aa8d7bf",
              "time": "20:59",
              "count_unreaded": 9
            },
            "user_id_from": "2468789",
            "user_id_to": "3702712"
          },
          {
            "id": 2,
            "uuid": "bd5db092-8752-4f57-9e81-9aa0725912cd",
            "created_at": "2022-09-18T21:18:05.496Z",
            "content": {
              "id": "3ef9a20c-7d72-4a6b-99d6-573ad52da129",
              "type": "chat",
              "content": {
                "text": "gbbht"
              },
              "self": false,
              "chat_id": "9ec67623-e03e-4b60-88b3-3d6d3aa8d7bf",
              "time": "21:18",
              "user_from_photo": "public/uploads/covers/166265726037813.pngbg",
              "name": "Alexander_S_S_",
              "count_unreaded": 10
            },
            "user_id_from": "2468789",
            "user_id_to": "3702712"
          }
        ],
        "letters": [
          {
            "id": 1,
            "uuid": "79ebfa52-aebc-437f-bd10-cf0b52a14a41",
            "created_at": "2022-09-18T20:59:25.120Z",
            "content": {
              "users_letters_id": "4eb47199-3959-4546-98e9-82d30e063943",
              "time": "20:59",
              "user_id_from": 2468789,
              "count_unreaded": 2,
              "type": "letter"
            },
            "user_id_from": "2468789",
            "user_id_to": "3702712"
          },
          {
            "id": 2,
            "uuid": "d17c1fff-327a-440c-bb3b-8eb6e5b4c5e0",
            "created_at": "2022-09-18T21:12:48.591Z",
            "content": {
              "users_letters_id": "4eb47199-3959-4546-98e9-82d30e063943",
              "time": "21:12",
              "user_id_from": 2468789,
              "count_unreaded": 3,
              "type": "letter"
            },
            "user_id_from": "2468789",
            "user_id_to": "3702712"
          },
          {
            "id": 3,
            "uuid": "61042a30-4d7b-437a-816b-0da8a116128a",
            "created_at": "2022-09-18T21:18:13.933Z",
            "content": {
              "users_letters_id": "4eb47199-3959-4546-98e9-82d30e063943",
              "time": "21:18",
              "user_id_from": 2468789,
              "name": "Alexander_S_S_",
              "count_unreaded": 4,
              "type": "letter"
            },
            "user_id_from": "2468789",
            "user_id_to": "3702712"
          },
          {
            "id": 4,
            "uuid": "c51260a3-ee7f-4ba8-a0e8-700ec5a0262e",
            "created_at": "2022-09-18T21:19:42.800Z",
            "content": {
              "users_letters_id": "4eb47199-3959-4546-98e9-82d30e063943",
              "time": "21:19",
              "user_id_from": 2468789,
              "user_from_photo": "public/uploads/covers/166265726037813.pngbg",
              "name": "Alexander_S_S_",
              "count_unreaded": 5,
              "type": "letter"
            },
            "user_id_from": "2468789",
            "user_id_to": "3702712"
          }
        ],
        "invites": []
      }
    }
  },
  methods: {
    ...mapMutations(['SetChatData', 'SetLetterData', 'SetDDData']),
    MessageClicked(value) {

      /*if ( this.communication_type === 1 ) {
        let index = this.chat_users_list.map(e => e.user_id.toString()).indexOf(value.user_id_from.toString());
        if ( index >= 0 ) {
          let chat_user = this.chat_users_list[index];
          this.SetChatData({ chat_user: chat_user });
        }
      }*/

      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/chat/${value.user_id}`,query:query});

      //this.SetDDData({ dd_message_del: { id: value.id, type: 1 } });

    },
    LetterClicked(value) {
      // console.log(value);

      /*if ( this.communication_type === 2 ) {
        let index = this.chat_users_list.map(e => e.user_id.toString()).indexOf(value.user_id_from.toString());
        // console.log('DDMessages:LetterClicked:', value, index);
        if ( index >= 0 ) {
          let chat_user = this.chat_users_list[index];
          this.SetLetterData({ letters: chat_user });
        }
      }*/

      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/letters/${value.user_id}`,query:query});


      //this.SetDDData({ dd_message_del: { id: value.users_letters_id, type: 2 } });

    },
  },
}
</script>

<style scoped>

</style>
