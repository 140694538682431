<template>
  <div class="container">
    <div class="panel-top">
      <div class="panel-top-side">
        <div class="panel-top-box">
          <h1>Favorites</h1>
        </div>
        <div class="panel-top-box">
          <div class="panel-top-count" v-if="favorites">{{ favorites.length }}</div>
        </div>
      </div>
      <div class="panel-top-side">
        <div class="panel-top-box" v-if="favorites && favorites.length>0">
          <button type="button" class="button button-transparent button-remove" v-if="favorites.length>0" @click="DeleteAll">Delete all</button>
        </div>
      </div>
    </div>
    <section class="search-result" v-if="favorites && favorites.length>0">
      <template v-for="(value,index) in favorites" v-bind:key="index">
        <article>
          <div class="person tip">
            <div class="person-image">
              <!--            <div-->
              <!--                class="person-image"-->
              <!--                @mouseover="value.hover = true"-->
              <!--                @mouseleave="value.hover = false"-->
              <!--            >-->
              <button type="button" class="person-remove" @click="DeleteFromFav(value.fav_id)"></button>
              <picture v-if="value.main_photo">
                <img :src="static_server + '/' + value.main_photo" alt="">
              </picture>
              <img v-if="value.mood_id" class="person-mood"
                   :src="'img/mood-'+(value.mood_id+1)+'.svg'"
                   :title="value.mood_description?value.mood_description:''">
              <ul class="person-media">
                <li class="person-photo" v-if="value.photosCount">{{ value.photosCount }}</li>
                <li class="person-video" v-if="value.videos_count">{{ value.videos_count }}</li>
              </ul>
            </div>
            <div class="person-main">
              <div class="person-main-row">
                <h2 class="person-name" :class="value.online?'is-online':''">
                  {{ value.user_name }}<template v-if="value.age">, {{ value.age }}</template><template v-if="value.country">, {{ value.country }}</template>
                </h2>
                <router-link :to="{path:`/user-view`,query:{...{id:value.id},...mid}}" class="person-profile">Profile</router-link>
              </div>
            </div>
          </div>
          <div class="person-comments">
            <time>{{
                new Date(value.created_at).getDate()
                  }}<sup>{{ nth(new Date(value.created_at).getDate()) }}</sup> {{ month[new Date(value.created_at).getMonth()] }} {{
                new Date(value.created_at).getFullYear()
                  }}
            </time>
            <div class="person-comment" @click="AddToFav(value.id,value.comment, value.fav_id)">Comment</div>
          </div>
          <div
              class="tip-content"
              :class="value.hover ? 'show':''"
              :id="'person-'+index"
              @mouseover="value.hover = true"
              @mouseleave="value.hover = false"
          >
            <div class="person-info">
              <div class="person-info-text" v-html="value.about_text"></div>
              <div class="person-info-interests">
                <strong>My interests:</strong>
                <ul class="interests">
                  <template v-for="(value2,index2) in value.interests" v-bind:key="index2">
                    <template v-for="(value3,index3) in interests" v-bind:key="index3">
                      <template v-if="value2.id === value3.id">
                        <li v-if="index2<5">
                          <label class="interest">
                            <input type="checkbox" checked disabled>
                            <span class="interest-content">
                                <i v-html="value3.icon"/>
                                <span>{{ value3.title }}</span>
                              </span>
                          </label>
                        </li>
                      </template>
                    </template>
                  </template>
                  <li v-if="value.interests.length > 5">
                    <router-link :to="{path:`/user-view`,query:{...{id:value.id},...mid}}" class="interest-more">
                      <i></i>
                      <span>{{ value.interests.length - 5 }} more </span>
                    </router-link>
                  </li>

                </ul>
              </div>
              <div class="person-info-buttons">
                <a @click="SendLetter(value)" class="button" v-if="value.buttons.letterBtn">Send letter</a>
                <router-link :to="{path:`/chat/${value.id}`,query:mid}" class="button" v-if="value.buttons.chatNowBtn">Chat now</router-link>
                <a @click="SendInvite(index)" class="button" v-if="value.buttons.inviteBtn">Invite</a>
              </div>
            </div>
          </div>
        </article>
      </template>
    </section>
    <template v-else>
      <div class="search-result-empty">{{ nothing_text }}</div>
    </template>

  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "Favorites",
  data: () => {
    return {
      favorites: [],
      nothing_text: 'Loading...',
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      interests: state => state.User.interests,
      search_result: state => state.Search.search_result,
      month: state => state.Main.month,
      reload_flag: state => state.Main.reload_flag,
    }),
    mid() {
      return this.$route.query.mid?{mid:this.$route.query.mid}:{};
    },
  },
  mounted() {
    this.GetFavData();
  },
  methods: {
    ...mapActions(['GetCall', 'SocketEmit']),
    ...mapMutations(['SetLetterData', 'SetPopupData', 'SetReloadFlag']),
    nth(n) {
      return n > 3 && n < 21 ? "th" : n % 10 == 2 ? "nd" : n % 10 == 2 ? "nd" : n % 10 == 3 ? "rd" : "th"
    },

    AddToFav(id, comment, fav_id) {
      this.SetPopupData({
        popup_additional_data: {
          user_id: id,
          comment: comment,
          fav_id: fav_id,
        },
        private_flag: false,
        popup_name: 'add_fav',
        popup_opened: true,
      })
    },
    GetFavData() {
      this.GetCall({
        endpoint: '/favorites',
        callback: (data) => {
          // console.log(data);
          this.nothing_text = 'Nothing found';
          this.favorites = Array.from(data);
        },
      })
    },

    DeleteFromFav(fav_id) {
      this.SetPopupData({
        popup_additional_data: {
          fav_id: fav_id,
          callback: () => {
            this.GetFavData();
          }
        },
        private_flag: false,
        popup_name: 'delete_fav',
        popup_opened: true,
      })
    },
    DeleteAll() {
      this.SetPopupData({
        popup_additional_data: {
          callback: () => {
            this.GetFavData();
          }
        },
        private_flag: false,
        popup_name: 'delete_fav_all',
        popup_opened: true,
      })
    },


    SendLetter(value) {
      // new_letter_data
      this.SetLetterData({
        write_letter: true,
        write_letter_user: value,
      });
      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/letters`, query:query});
    },
    SendInvite(index) {
      let send = {
        type: 'invite',
        data: {
          user_id: this.search_result[index].id,
          text: 'It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.'
        },
      }
      this.SocketEmit(send);
      let query = this.$route.query.mid?{mid:this.$route.query.mid}:{};
      this.$router.push({path: `/invites`, query:query});
    },

    GoToPath(path, obj) {
      this.$router.push({path: path, query: obj});
    },

  },
  watch: {
    reload_flag: {
      handler(newVal) {
        if (newVal) {
          this.GetFavData();
          this.SetReloadFlag(false);
        }
      },
      deep: false
    },
  },
}
</script>

<style scoped>

</style>
