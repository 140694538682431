<template>
  <footer>
    <div class="container">
      <nav class="footer-nav">
        <ul>
<!--          <li>-->
<!--            <a href="#">News</a>-->
<!--          </li>-->
          <li>
            <router-link :to="{path:`/faq`,query:mid}">F.A.Q</router-link>
          </li>
          <li>
            <a href="/terms_and_conditions.pdf">Terms and conditions</a>
          </li>
<!--          <li>-->
<!--            <a href="#">Love story</a>-->
<!--          </li>-->
          <li>
            <router-link :to="{path:`/contacts`,query:mid}">Contacts</router-link>
          </li>
        </ul>
      </nav>
      <div class="footer-copyright">&copy; {{ new Date().getFullYear() }} All rights reserved</div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
  computed:{
    mid() {
      return this.$route.query.mid?{mid:this.$route.query.mid}:{};
    },
  }
}
</script>

<style scoped>

</style>
