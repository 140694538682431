<template>
  <dialog class="popup" id="adv-search">
    <perfect-scrollbar
        :watchOptions="true"
        :options="scroll_options"
        class="popup-box"
        style="max-width: 736px;">
      <div class="popup-header popup-header-row">
        <div class="popup-header-box">Search for Your Matches</div>
        <div class="popup-header-box">
          <GButton
              text="Reset"
              button_style="transparent"
              @click="Reset"
          />
        </div>
      </div>
      <button type="button" class="popup-close" @click="hide"></button>
      <form class="form adv-search" :class="advanced ? 'is-opened':''">
        <div class="popup-block">
          <div class="adv-search-top">
            <div class="sex">
              <label class="sex-male" title="Male">
                <input type="radio" value="male" v-model="sex">
                <span></span>
              </label>
              <label class="sex-female" title="Female">
                <input type="radio" value="female" v-model="sex">
                <span></span>
              </label>
            </div>
            <div class="range" title="Age">
              <GRange
                  v-model="range"
                  :range_min="age_range_min"
                  :range_max="age_range_max"
                  class="space_bottom_45"
              />
              <!--              <div class="range-box" data-min="0" data-max="100" data-start-min="22" data-start-max="56"></div>-->
            </div>
          </div>
        </div>
        <!--
                <div class="popup-block">
                  <fieldset>
                    <label>Country, city (Enter 2 or more countries separated by semicolons)</label>
                    <input type="text" class="textfield">
                  </fieldset>
                </div>
        -->
        <div class="popup-block">
          <div class="form-row">
            <div class="form-row-box">
              <label class="checkbox">
                <input type="radio" id="adv-search-online" value="true" v-model="online">
                <span>Online</span>
              </label>
            </div>
            <div class="form-row-box">
              <label class="checkbox">
                <input type="radio" id="adv-search-offline" value="false" v-model="online">
                <span>Offline</span>
              </label>
            </div>
            <!--            <div class="form-row-box">-->
            <!--              <label class="checkbox">-->
            <!--                <input type="checkbox">-->
            <!--                <span>Avaliable for video Chat</span>-->
            <!--              </label>-->
            <!--            </div>-->
          </div>
        </div>
        <!--
                        <div class="popup-block">
                          <ul class="tags">
                            <li>
                              <button type="button" class="button">Biking</button>
                            </li>
                            <li>
                              <button type="button" class="button">Dancing</button>
                            </li>
                          </ul>
                        </div>
        -->
        <div class="adv-search-hidden">
          <button type="button" class="adv-search-close" @click="GoAdvanced(false)">Close more option</button>
          <div class="popup-block">
            <div class="form-title">Mood</div>
            <ul class="mood-select" v-if="filterMood">
              <template v-for="(value,index) in filterMood" v-bind:key="index">
                <li>
                  <label>
                    <input type="checkbox" v-model="value.selected">
                    <span>
                      <img :src="value.ico" alt="">
                      <i>{{ value.name }}</i>
                    </span>
                  </label>
                </li>
              </template>
            </ul>
          </div>
          <div class="popup-block">
            <ul class="adv-search-tabs-nav" v-if="filterCategories">
              <template v-for="(value,index) in filterCategories" v-bind:key="index">
                <li>
                  <button
                      type="button"
                      class="button-link"
                      :class="value.selected ? 'is-active':''"
                      @click="CategoryRange(index)"
                  >
                    {{ value.title }}
                  </button>
                </li>
              </template>
            </ul>
            <ul class="adv-search-tabs-content" v-if="filterCategories && filterCategories.length > 0">
              <template v-for="(value,index) in filterCategories[category_selected].options" v-bind:key="index">
                <li>
                  <label>
                    <input type="checkbox"
                           name="adv-search-tabs"
                           :id="'adv-search-tabs-'+index"
                           v-model="value.selected"
                    >
                    <span>{{ value.title }}</span>
                  </label>
                </li>
              </template>
            </ul>
          </div>
          <div class="popup-block">
            <div class="form-title">
              <div class="form-title-row">
                <div class="form-title-box">Interests</div>
                <div class="form-title-box">
                  <div class="swiper-nav">
                    <button type="button" @click="PrevSlide" class="swiper-prev"></button>
                    <button type="button" @click="NextSlide" class="swiper-next"></button>
                  </div>
                </div>
              </div>
            </div>
            <div class="adv-search-interests" v-if="filterInterests && filterInterests.length > 0">
              <swiper
                  slides-per-view="auto"
                  :space-between="20"
                  :loop="true"

                  @swiper="onSwiper"
                  @slideChange="onSlideChange"
                  ref="swiper"
              >
                <template v-for="(value, index) in filterInterests" v-bind:key="index">
                  <swiper-slide class="swiper-slide">
                    <label class="interest">
                      <input type="checkbox" v-model="value.selected">
                      <span class="interest-content">
                            <i>
                              <img :src="static_server+'/'+ value.icon1">
                            </i>
                            <span>{{ value.title }}</span>
                          </span>
                    </label>
                  </swiper-slide>
                </template>
              </swiper>
            </div>
          </div>
        </div>
        <div class="popup-bottom">
          <div class="popup-bottm-buttons">
            <button type="button" class="button button-transparent adv-search-open" @click="GoAdvanced">Add more option</button>
            <div class="button" @click="GetResult">Show results</div>
          </div>
        </div>
      </form>
    </perfect-scrollbar>
  </dialog>
</template>

<script>
// import Swiper core and required components
import SwiperCore from "swiper";
import {Navigation, Pagination, A11y} from "swiper/modules";

// Import Swiper Vue.js components
import {Swiper, SwiperSlide} from "swiper/vue";

// Import Swiper styles
// import "swiper/swiper.scss";
// import "swiper/components/navigation/navigation.scss";
// import "swiper/components/pagination/pagination.scss";

// install Swiper components
SwiperCore.use([Navigation, Pagination, A11y]);

import {mapActions, mapMutations, mapState} from "vuex";
import GButton from "@/G_UI/GButton";
import GRange from "@/G_UI/GRange";
import router from "@/router";

// const swiper = useSwiper();

export default {

  name: "PSearch",
  components: {
    Swiper, SwiperSlide,
    GRange, GButton,

  },

  data: function () {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
      is_open: false,
      advanced: false,
      category_selected: 0,

      range: [19, 100],
      age_range_min: 19,
      age_range_max: 100,

      online: true,
      sex: 'male',
      filterMood: [],
      filterInterests: [],
      filterCategories: [],

      filterPattern: {
        mood: [],
        interests: [],
        categories: [],
      },

      page: 1,
      page_size: 20,
    }
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      is_auth: state => state.User.is_auth,
      mood: state => state.FakeData.filterMood,
      // filterCategories: state => state.FakeData.filterCategories,
      // filterInterests: state => state.FakeData.filterInterests,
      about_me_dd_data: state => state.User.about_me_dd_data,
      my_lifestyle_dd_data: state => state.User.my_lifestyle_dd_data,
      interests: state => state.User.interests,
    }),
  },
  mounted() {
    this.filterInterests = Array.from(this.interests);
    this.filterMood = Array.from(this.mood);
    this.filterCategories = Array.from(this.about_me_dd_data);

    setTimeout(() => {
      this.is_open = true;

      for (let i = 0; i < this.filterCategories.length; i++) {
        this.filterCategories[i].selected = i === 0;
        //console.log(this.categories[i].selected);
      }
    }, 100);

    this.filterPattern = {...router.currentRoute._value.query};

    if (this.filterPattern.sex) {
      this.sex = this.filterPattern.sex;
    }
    if (this.filterPattern.agemin) {
      this.range[0] = this.filterPattern.agemin;
    }
    if (this.filterPattern.agemax) {
      this.range[1] = this.filterPattern.agemax;
    }
    if (this.filterPattern.online) {
      this.online = this.filterPattern.online;
    }
    if (this.filterPattern.mood && this.filterPattern.mood.length > 0) {
      if(Array.isArray(this.filterPattern.mood)) {
        this.filterPattern.mood.forEach((e) => {
          this.filterMood.forEach((e2) => {
            if (e2.id === parseInt(e)) {
              e2.selected = true;
            }
          });
        });
      }else{
        this.filterMood.forEach((e2) => {
          if (e2.id === parseInt(this.filterPattern.mood)) {
            e2.selected = true;
          }
        });
      }
    }
    if (this.filterPattern.interests && this.filterPattern.interests.length > 0) {
      if(Array.isArray(this.filterPattern.interests)) {
        this.filterPattern.interests.forEach((e) => {
          this.filterInterests.forEach((e2) => {
            if (e2.id === e) {
              e2.selected = true;
            }
          });
        });
      }else{
        this.filterMood.forEach((e2) => {
          if (e2.id === this.filterPattern.interests) {
            e2.selected = true;
          }
        });
      }
    }
    if (this.filterPattern.categories && this.filterPattern.categories.length > 0) {
      this.filterPattern.categories.forEach((ep) => {
        this.filterCategories.forEach((e) => {
          e.options.forEach((e2) => {
            let ep_arr = ep.split(' ');
            if (ep_arr[0] === e.id && ep_arr[1] === e2.id) {
              e2.selected = true;
            }
          });
        });
      });
    }
  },
  methods: {
    ...mapMutations(['SetPopupData', 'SetSearchResult']),
    ...mapActions(['PostCall', 'GetCall', 'SetUrlQuery', 'GetSearchResult']),

    InitSlider() {
      /*
            new Swiper($('.adv-search-interests .swiper-container')[0], {
              loop: true,
              loopedSlides: $('.adv-search-interests .interest').length,
              slidesPerView: 'auto',
              speed: 500,
              spaceBetween: 15,
              navigation: {
                prevEl: $('.adv-search-interests').prev().find('.swiper-prev')[0],
                nextEl: $('.adv-search-interests').prev().find('.swiper-next')[0]
              }
            });
      */
    },
    PrevSlide() {
      this.swiper.slidePrev();
    },
    NextSlide() {
      this.swiper.slideNext();
    },
    onSwiper(swiper) {
      //console.log(swiper);
      this.swiper = swiper;
    },
    onSlideChange() {
      // console.log('slide change')
    },

    GoAdvanced(act = true) {
      this.advanced = act;
    },

    Reset() {

    },

    CategoryRange(index) {
      this.category_selected = index;
      this.filterCategories.forEach(elem => {
        elem.selected = false
      });
      this.filterCategories[index].selected = true;
    },
    CategorySelected(id) {
      for (let i = 0; i < this.categories[this.category_selected].options.length; i++) {
        let elem = this.categories[this.category_selected].options[i];
        elem.selected = elem.id === id;
      }
    },
    MoodSelected(id) {
      let moodIndex = this.filterPattern.mood.indexOf(id);
      if (moodIndex === -1) {
        this.filterPattern.mood.push(id);
      } else {
        this.filterPattern.mood.splice(moodIndex, 1);
      }
    },
    InterestSelected(id) {
      let interestIndex = this.filterPattern.interests.indexOf(id);
      if (interestIndex === -1) {
        this.filterPattern.interests.push(id);
      } else {
        this.filterPattern.interests.splice(interestIndex, 1);
      }
    },


    async GetResult() {
      this.hide();

      if (this.sex) this.filterPattern.sex = this.sex;
      if (this.range[0]) this.filterPattern.agemin = this.range[0];
      if (this.range[1]) this.filterPattern.agemax = this.range[1];
      if (this.online) this.filterPattern.online = this.online;

      let tempMood = this.filterMood.filter(e => e.selected).map(e => e.id);
      if (tempMood && tempMood.length > 0)
        this.filterPattern.mood = Array.from(tempMood);
      else
        this.filterPattern.mood = [];

      let tempInterests = this.filterInterests.filter(e => e.selected).map(e => e.id);
      if (tempInterests && tempInterests.length > 0)
        this.filterPattern.interests = Array.from(tempInterests);
      else
        this.filterPattern.interests = [];

      let tempCategories = [];
      this.filterPattern.categories = [];
      this.filterCategories.forEach((e, i) => {
        e.options.forEach((e2, i2) => {
          if (e2.selected) {
            console.log(i, i2, e2);
            tempCategories.push(e.id + " " + e2.id);
          }
        });
      });
      if (tempCategories && tempCategories.length > 0) this.filterPattern.categories = Array.from(tempCategories);


      /*await this.SetUrlQuery({
        add: [
          {key: 's', val: this.filterPattern.sex},
          {key: 'o', val: this.filterPattern.online},
          {key: 'm', val: this.filterPattern.mood},
          {key: 'i', val: this.filterPattern.interests},
          {key: 'c', val: this.filterPattern.categories},
        ]
      });*/

      console.log('this.filterPattern', this.filterPattern);
      let query = {...this.$route.query,...this.filterPattern};

      await router.replace({path: '/search-result', query: query});

      const sendOptions = {...this.filterPattern, ...{size: this.page_size, page: this.page}};
      this.GetSearchResult(sendOptions);


      // this.GetCall({
      //   endpoint: '/search/users',
      //   options: this.filterPattern,
      //   callback: (data) => {
      //     this.SetSearchResult(data);
      //     this.hide();
      //     router.push({ path: '/search-result' });
      //   },
      //   on_error: (error) => {
      //     let error_status = error.response.status;
      //     console.log(error_status);
      //   },
      // })
    },

    hide() {
      this.is_open = false;
      setTimeout(() => {
        this.SetPopupData({popup_opened: false});
      }, 300);
    },
  },
  watch: {
    is_auth(newVal) {
      if (newVal === false) {
        this.hide();
      }
    },
  },
}
</script>

<style scoped>

</style>
