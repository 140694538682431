<script>
export default {
  name: "PageNotFound"
}
</script>

<template>
  <div class="search-result-empty" style="flex-flow: column;">
    <span style="margin-bottom: 20px">Error 404</span>
    <router-link to="/user-profile" style="font-size: 20px">Back to profile</router-link>
  </div>
</template>

<style scoped>

</style>
