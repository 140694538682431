<template>
  <div class="chat-user"
       :class="invite.user_id === communication.user_id ? 'is-active':''"
       @click="UserClicked">
    <div class="chat-user-avatar" :class="communication.online ? 'is-online':''" @click="GoToUserView">
      <picture v-if="communication && communication.user_photo_url">
        <!--              <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
        <!--              <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
        <img :src="static_server+'/'+communication.user_photo_url" alt="">
      </picture>
    </div>
    <div class="chat-user-info">
      <div class="chat-user-name-date">
        <div class="chat-user-name">{{ communication.user_name }}</div>
        <time class="chat-user-date" v-if="communication && communication.updated_at">
          {{ new Date(communication.updated_at).getHours() }}:{{ new Date(communication.updated_at).getMinutes() }}
        </time>
      </div>
      <div class="chat-user-links-about">
<!--        <ul class="chat-user-links">-->
<!--          <li class="chat-user-links-photo"></li>-->
<!--          <li class="chat-user-links-video"></li>-->
<!--        </ul>-->
        <div class="chat-user-about">{{ communication.message }}</div>
        <div class="chat-user-messages-dummy" v-if="communication.user_unread_count > 0">{{ communication.user_unread_count }}</div>
        <div title="number of unread messages"
             class="chat-user-unread-messages" v-if="communication.user_unread_count > 0">{{ communication.user_unread_count }}</div>
      </div>
    </div>
  </div>
</template>

<script>
import {mapActions, mapMutations, mapState} from "vuex";

export default {
  name: "I_InviteElement",
  props: {
    communication: Object,
  },
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      invite: state => state.Invites.invite,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
      },
    }
  },
  mounted() {
    if (this.communication.user_id === this.invite.user_id) {
      this.$nextTick(() => {
        this.UpdateInvite({user: this.communication, options: {page: 0}});
      });
    }
  },
  methods: {
    ...mapActions(['GetCall','UpdateInvite']),
    ...mapMutations(['SetInviteData']),

    UserClicked() {
      this.SetInviteData({
        invite_id: this.communication.user_id,
        invite: this.communication,
        open_mobile: true,
      });
      this.UpdateInvite({user: this.communication, options: {page: 0}});
    },
    GoToUserView(e){
      e.stopPropagation();
      let query = this.$route.query.mid?{mid:this.$route.query.mid,id:this.communication.user_id}:{id:this.communication.user_id};
      this.$router.push({path: `/user-view`,query: query});
    },
  },
}
</script>

<style scoped>

</style>
