<template>
  <ul class="page-tabs">
    <li>
      <router-link :to="{path: `/invites/`,query:this.mid}" class="page-tabs-invite"
           :class="communication_type === 0 ? 'is-active' : ''"
           @click.prevent="ChangeTab(0)">
        <span>Invite</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{path: `/chat/`,query:this.mid}" class="page-tabs-chat"
           :class="communication_type === 1 ? 'is-active' : ''"
           @click.prevent="ChangeTab(1)">
        <span>Chat</span>
      </router-link>
    </li>
    <li>
      <router-link :to="{path: `/letters/`,query:this.mid}" class="page-tabs-letter"
           :class="communication_type === 2 ? 'is-active' : ''"
           @click.prevent="ChangeTab(2)">
        <span>Letter</span>
      </router-link>
    </li>
  </ul>
</template>

<script>
import {mapMutations, mapState} from "vuex";

export default {
  name: "CommunicationTabs",
  computed: {
    ...mapState({
      communication_type: state => state.Communications.communication_type,
      user_data: state => state.User.user_data,
      mid(){
        return this.$route.query.mid?{mid:this.$route.query.mid}:{};
      }
    }),
  },
  methods: {
    ...mapMutations(['SetCommunicationData', 'ResetChatData','ResetInviteData', 'SetChatData', 'SetInviteData']),
    ChangeTab(communication_type) {
      this.SetChatData({
        open_mobile: false,
      });
      this.SetInviteData({
        open_mobile: false,
      });

      if (communication_type !== this.communication_type) {
        this.ResetInviteData();
        this.ResetChatData();
        this.SetCommunicationData({
          communication_type: communication_type,
        });

        //let query = Object.assign({}, this.$route.query);
        // delete query.id;

        switch (communication_type) {
          case 0:
            this.$router.push({path: `/invites/`,query:this.mid});
            // query = { ...query, ...{ type: 'invite' } }
            break;
          case 1:
            this.$router.push({path: `/chat/`,query:this.mid});
            // query = { ...query, ...{ type: 'chat' } }
            break;
          case 2:
            this.$router.push({path: `/letters/`,query:this.mid});
            // query = { ...query, ...{ type: 'letter' } }
            break;
          default:
            this.$router.push({path: `/invites/`,query:this.mid});
            // query = { ...query, ...{ type: 'invite' } }
            break;
        }

        // this.$router.replace({ query: query });
      }
    },
  },
}
</script>

<style scoped>

</style>
