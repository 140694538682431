<template>
  <perfect-scrollbar
      :watchOptions="true"
      :options="scroll_options"
      class="tip-content" id="notifications">
    <div class="notifications">
      <template v-for="(value,index) in dd_favorites" v-bind:key="index">
        <div class="notification">
          <div class="notification-image">
            <picture>
              <!--            <source srcset="pic/avatar.webp 1x, pic/avatar@2x.webp 2x" type="image/webp">-->
              <!--            <source srcset="pic/avatar.png 1x, pic/avatar@2x.png 2x" type="image/png">-->
              <img :src="static_server + '/' + value.main_photo" alt="">
            </picture>
          </div>
          <div class="notification-content">
            <div class="notification-header">{{ value.user_name }}, {{ value.age }}</div>
            <div class="notification-text notification-fav">Added you to favorites</div>
            <a class="button button-block button-user" @click="FavClicked(value)">Show Profile</a>
          </div>
        </div>
      </template>
    </div>
  </perfect-scrollbar>

</template>

<script>
import { mapMutations, mapState } from "vuex";

export default {
  name: "DDFavorites",
  computed: {
    ...mapState({
      static_server: state => state.Main.static_server,
      dd_favorites: state => state.Dropdowns.dd_favorites,
      // chat_users_list: state => state.Communications.chat_users_list,
      // chat_type: state => state.Communications.chat_type,
    }),
  },
  data: () => {
    return {
      scroll_options: {
        suppressScrollX: true,
        // scrollYMarginOffset: -15
      }

    }
  },
  methods: {
    ...mapMutations(['SetDDData']),
    FavClicked(value) {

      // this.SetDDData({ dd_favorite_del: value.id });
      //
      let query = this.$route.query.mid?{mid:this.$route.query.mid,id: value.id}:{id: value.id};
      this.$router.push({ path: '/user-view', query: query });
    },
  },
}
</script>

<style scoped>

</style>
